
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        


























































































































































































































































































































































#class-add {
  @include sub-page;

  .form {
    @include forms;
  }
}
