
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






















































































.pagination-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 20px;
  margin: auto 0 20px auto;
  justify-content: center;

  .multiselect-view {
    width: 70px;

    @media screen and (min-width: 768px) {
      width: 100px;
    }
  }
}
