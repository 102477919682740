
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






































































































































































































#profile-view {
  @include sub-page;

  .form {
    @include forms;
  }
}
