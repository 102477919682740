
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        












































































































.pagination {
  display: inline-flex;
  align-items: center;
  margin: 0;
  gap: 0 10px;

  .dots {
    color: $accent-color;
    cursor: default;
  }

  .pagination-list {
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    column-gap: 10px;
  }

  li {
    $item-size: 30px;
    margin: 0;
    min-width: $item-size;
    height: $item-size;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $accent-color;
    cursor: pointer;

    &.is-current {
      border: 1px solid rgba(192, 192, 192, 0.226);
      border-radius: 10px;
      background: white;
      box-shadow: inset 2px 2px 2px 0px rgb(192 192 192 / 38%);
    }
  }

  .pagination-link {
    border-radius: 0;
  }
}
