
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






































































































































































































































#class-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
