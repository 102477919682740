
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






















































































































































































#membership-types-add {
  @include sub-page;

  .form {
    @include forms;

    .price {
      color: $accent-color;
    }
  }
}
