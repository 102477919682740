
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        



































































































































































































.custom-table-list {
  @include table-elements;
  $card-shadow: 0px 1px 7px 1px $global-shadow-color;
  display: grid;
  flex-direction: column;
  padding: 10px 2px 10px 2px;
  color: #a19fa8;
  font-weight: 500;
  overflow: auto;

  .header {
    display: flex;
    column-gap: 5px;
    margin: 0 8px 20px 8px;
    padding: 13px 10px 15px;
    border-radius: 6px;
    background: lighten($color: $accent-color-light, $amount: 0);
    color: $accent-color;
    // box-shadow: $card-shadow;
    user-select: none;
    overflow: hidden;
    border-left: 6px solid;
    border-left-color: transparent;
    // min-width: 0;

    // > .cell {
    //   flex: 1 1 100px;
    // }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: $table-text;
    // min-width: 0;
    // overflow: hidden;

    .row {
      display: flex;
      column-gap: 5px;
      padding: 0 10px;
      margin: 0 8px 0 8px;
      background: white;
      box-shadow: $card-shadow;
      border-radius: 6px;
      transition: all 0.2s ease;
      border-left: 6px solid;
      border-left-color: transparent;
      overflow: hidden;

      &.selectable {
        user-select: none;
        cursor: pointer;
      }

      &.disabled-low {
        background: #00000012;
        opacity: 0.7;
        cursor: not-allowed;
      }

      &:hover {
        border-left-color: $accent-color;
        // transform: translateX(4px);
        box-shadow: 0px 2px 7px 0px darken($global-shadow-color, 50);
      }

      > .cell {
        display: inline-block;
        // flex: 1 1 100px;
      }
    }
  }
}
