
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






































































































































































































































































































.table-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  .search,
  .members,
  .active-status,
  .client-type,
  .notification-filter {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }

  .search {
    input {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }

  .members {
    // Remove later
    display: flex;
    align-items: center;

    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 180px;
      }
    }

    > span {
      display: flex;
      align-items: center;
    }
  }

  .active-status {
    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 120px;
      }
    }
  }

  .client-type {
    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 190px;
      }
    }
  }

  .notification-filter {
    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 190px;
      }
    }
  }
}
