
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        
































.table-cell {
  padding: 14px 0;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 0;

  span {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
