
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        




























































































.checkbox-wrap {
  $toggle-width: 40px;
  $switch-width: 18px;
  $toggle-back: #dcdcdc;
  $checkbox-checked-back: white;
  display: inline-flex;

  .checkbox-group {
    display: inline-flex;
    align-items: center;
    user-select: none;

    #checkbox-input {
      min-width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .checkbox-inner-box {
        background: $toggle-back;
        border: 1px solid rgba(87, 87, 87, 0.643);
        border-radius: 7px;
        padding: 2px 2px 2px 3px;
        width: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 1.4s ease;

        svg {
          opacity: 0;
        }
      }

      .toggle-wrap {
        width: $toggle-width;
        padding: 0 5px;
        height: 60%;
        position: relative;

        .toggle {
          background: $toggle-back;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          display: flex;
          align-items: center;

          .switch {
            width: $switch-width;
            height: $switch-width;
            background: white;
            border: 1px solid #34373d45;
            position: absolute;
            left: 0px;
            border-radius: 50%;
            transition: all 0.5s ease;
          }
        }
      }

      &[checked] {
        .checkbox-inner-box {
          background: $checkbox-checked-back;

          svg {
            opacity: 1;
            transition: opacity 0.2s ease;
            fill: none;
            path {
              stroke: $accent-color;
              stroke-width: 2.5;
              stroke-linecap: round;
              stroke-linejoin: round;
            }
          }
        }

        .toggle-wrap {
          .toggle {
            // background: lighten($color: $toggle-back, $amount: 10);
            .switch {
              // background: lighten($color: #d34849, $amount: 5);
              background: $accent-color;
              left: calc(#{$toggle-width} - #{$switch-width});
            }
          }
        }
      }
    }

    label {
      user-select: none;
      cursor: pointer;
    }

    label.left {
      margin-right: 8px;
    }

    label.right {
      margin-left: 8px;
    }
  }
}
