
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        















































































@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');
* {
  box-sizing: border-box;
  scrollbar-width: auto;
  scrollbar-color: lighten($color: $accent-color, $amount: 10)
    rgb(240, 240, 240);
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

#app {
  min-height: 100%;
  // min-height: 100vh;
  // min-width: 99vw;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background: white;
}

::-webkit-scrollbar-thumb {
  background: lighten($color: $accent-color, $amount: 10);
  border-radius: 4px;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
