
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        















































































































































































































































#group-add {
  @include sub-page;

  .form {
    @include forms;
  }
}
