
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        





























































































































































































.custom-table-list {
  @include table-elements;
  $card-shadow: 0px 1px 7px 1px $global-shadow-color;
  display: flex;
  flex-direction: column;
  color: #a19fa8;
  font-weight: 500;
  font-size: 0.85rem;

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: $table-text;
    padding: 5px;
    // padding: 10px 20px;
    // overflow: hidden;

    .row {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-template-areas:
        'text slot'
        'text slot';
      grid-auto-rows: minmax(40px, auto);
      padding: 10px 12px;
      background: white;
      box-shadow: $card-shadow;
      border-radius: 5px;
      transition: all 0.2s ease;
      overflow: auto;
      // border-left: 4px solid;
      // border-left-color: silver;

      @media screen and (min-width: 768px) {
        grid-auto-rows: 40px;
      }

      &.selectable {
        user-select: none;
        cursor: pointer;
      }

      &.disabled-low {
        background: #00000012;
        opacity: 0.7;
        cursor: not-allowed;
      }

      &:hover {
        border-left-color: $accent-color;
        // transform: translateX(5px);
        box-shadow: 0px 2px 7px 0px darken($global-shadow-color, 50);
      }

      > .cell {
        display: flex;

        .cell-wrapper {
          display: flex;
          flex: 1;
          align-items: center;
          gap: 10px;

          &.end {
            justify-content: flex-end;
          }
        }

        // WIP
        // &:not(.slot) {
        //   grid-area: text;
        // }

        // &.slot {
        //   grid-area: slot;
        // }

        .table-cell {
          padding: 3px 0;
        }
      }
    }
  }
}
