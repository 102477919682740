
          @import "@/assets/css/vars.scss";
          @import "@/assets/css/global.scss";
          @import "@/assets/css/tooltip.scss";
        






















#spinner-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
